// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body,\nhtml {\n    position: relative;\n    padding: 0px;\n    margin: 0px;\n    min-height: 100%;\n    width: 100%;\n    font-size: 95%;\n    font-family: 'Open Sans';\n}\n\n.S2__5qrTmMLb5Q1dFlOm {\n    position: relative;\n}\n\n\n/* width */\n::-webkit-scrollbar {\n    width: 10px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n    background: #fff;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n    background: #d2d2d2;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n    background: #c2c2c2;\n}", "",{"version":3,"sources":["webpack://./src/common-style.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,WAAW;IACX,cAAc;IACd,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;AACtB;;;AAGA,UAAU;AACV;IACI,WAAW;AACf;;AAEA,UAAU;AACV;IACI,gBAAgB;AACpB;;AAEA,WAAW;AACX;IACI,mBAAmB;AACvB;;AAEA,oBAAoB;AACpB;IACI,mBAAmB;AACvB","sourcesContent":["body,\nhtml {\n    position: relative;\n    padding: 0px;\n    margin: 0px;\n    min-height: 100%;\n    width: 100%;\n    font-size: 95%;\n    font-family: 'Open Sans';\n}\n\n.position-relative {\n    position: relative;\n}\n\n\n/* width */\n::-webkit-scrollbar {\n    width: 10px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n    background: #fff;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n    background: #d2d2d2;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n    background: #c2c2c2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"position-relative": "S2__5qrTmMLb5Q1dFlOm"
};
export default ___CSS_LOADER_EXPORT___;
