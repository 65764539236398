const MuiList = {
    MuiList: {
        styleOverrides: {
            root: {
                padding: "0px",
            },
        },
    },
}

export {
    MuiList
}