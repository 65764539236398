import axios from "axios";
import {
  setDataToLocalStorage,
  getFromLocalStorage,
  isTokenExpired,
  removeFromLocalStorage
} from "../storage";
import JWTDecode from "jwt-decode";

/**
 * This is use to do all type of request (i.e. get, post, put, delete, etc ..)
 * Passing the loggedin information like token and other details in header in every http(s) request
 * @param {*} url
 * @param {*} payload
 * @param {*} headers
 * @param {*} method
 */
let authorizeRequestAPI = async (url, payload, method,blob = false) => {

  // Reading stored token from local storage
  let user = getFromLocalStorage("user");
  if (user == null || user == "undefined") {
    return;
  }

  let { token } = JSON.parse(user);
  // Check token expire on each api call if expire remove the token and send user to login screen
  if (await isTokenExpired(token)) {
    removeFromLocalStorage('user');
    return;
  }

  // Read the login header if user is not passing it
  // Prepare header allow user to add any header related stuff
  let prepareHeader = {
    "access-token": `${token}`,
    "Content-Type": "application/json",
    "Accept": "application/json"
  };
  if(blob){
    prepareHeader["responseType"]= "blob"
  }
  let headers = prepareHeader;
  return new Promise((resolve, reject) => {
    axios({ method, url, data: payload, headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log("error",error)
        reject(error.data);
      });
  });
};

/**
 * This is un-authorized api call,
 * @param {*} url 
 * @param {*} payload 
 * @param {*} method 
 * @param {*} headers 
 * @returns 
 */
let unAuthorizeRequestAPI = async (url, payload, method) => {

  let headers = {
    "Content-Type": "application/json",
    "Accept": "application/json"
  };
  return new Promise((resolve, reject) => {
    axios({ method, url, data: payload, headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};




/**
 * This is use to login the user into the system
 * @param payload
 */
let appLogin = async (url, payload) => {
  let response = await axios.post(url, payload).catch((error) => {
    throw error.response.data;
  });
  if (!response) {
    throw { message: "Invalid response from login api!" };
  }
  let { data } = response;
  let { access } = data;
  let userData = JWTDecode(access);
  setDataToLocalStorage("token", JSON.stringify(data));
  setDataToLocalStorage("loggedInUser", JSON.stringify(userData));
  return data;
};

export { authorizeRequestAPI, appLogin, unAuthorizeRequestAPI };
