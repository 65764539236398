
// Dev url (https://dev.graphemelabs.ai)
import { API_ROOT } from "../../envrionment";

const baseURL = API_ROOT;

const API_URLS = {
    login_url: `${baseURL}/login/auth`,
    register_url: `${baseURL}/register`,
    search_form_field_url: `${baseURL}/search/form/fields`,
    search_result_url: `${baseURL}/search/elk/query`,
    search_result_export: `${baseURL}/search/elk/export`,
    searched_history_url: `${baseURL}/search/user/searched/history`,
    searched_history_archive_url: `${baseURL}/search/user/searched/archive`,
    searched_history_delete_url: `${baseURL}/search/user/searched/delete`,
    searched_one_patent_details_url: `${baseURL}/search/user/searched/one/patent/details`,
    mail_verification_url: `${baseURL}/authentication/email/verification`,
    forget_password_url: `${baseURL}/credentails/reset/password/request`,
    reset_password_url: `${baseURL}/credentails/reset/password/update`,
    project_save: `${baseURL}/project/save`,
    project_delete: `${baseURL}/project/delete`,
    all_user_project: `${baseURL}/project/all`,
    individual_project: `${baseURL}/project/one/details`,
    predicated_classfication_url : `${baseURL}/project/predicated/classification`,
    image_url : `${baseURL}/project/patent/s3/image`,
    save_relevant_text_url : `${baseURL}/project/save/relevant-text`,
    subscription_details_get :  `${baseURL}/package/subscription/details/get`,
    service_offered_get :  `${baseURL}/service/offered/get`,
    pdf_display_url: `${baseURL}/pdf/display`,
}

export {
    API_URLS
}