import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./assets/themes";
import AppRouters from "./routers";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import Loader from "./components/loader";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <AppRouters />
        </BrowserRouter>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
