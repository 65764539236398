import { Suspense } from "react";
import Header from '../components/header'
import Loader from "../components/loader";

const AuthorizedLayout = (props) => {
    let { children } = props;
    return (
        <>
            <Suspense fallback={<Loader/>}>
                <Header children={children}/>
            </Suspense>
        </>
    )
}

export default AuthorizedLayout;