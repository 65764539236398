let COLORS = {
    RED: "#FF0C1A",
    WHITE: "#ffffff",
    BLACK: "#323232",
    PRIMARY : "#03579D",
    SECONDARY : "#ffa300",
    LIGHT_GREY : "#f1f1f1",
    LIGHT_BLACK : "#333333",
    BLUE : "#0E98FC",
    SUCCESS : "#1FC780",
    ERROR: "#FF0C1A",
    WARNING : "#FBB539",
    GREY1 : "#4d4d4d",
    GREY2 : "#939598",
    GREY3 : "#c6c8ca",
    GREY4 : "#e6e7e8",
}

export {
    COLORS
}