import { createTheme } from "@mui/material/styles";
import { COLORS } from "../styles/colors";
import { MUIButton } from "../themes/AppButton";
import { MUIAppBar } from "../themes/AppBar";
import { MUIAppDialog } from "../themes/AppDailog";
import { MUITextInput } from "./AppTextInput";
import { MUIAppSelect } from "./AppFormFields";
import { MUITypography } from "./AppTypography";
import { MUIFonts } from "./AppFonts";
import { MuiList } from "./MenuItem";
/**
 * Color palette, define you color palette here
 */
const palette = {
  palette: {
    primary: {
      main: COLORS.PRIMARY,
    },
    secondary: {
      main: COLORS.SECONDARY,
    },
  },
};

const components = {
  components: {
    ...MUIButton,
    ...MUIAppBar,
    ...MUIAppDialog,
    ...MUIAppSelect,
    ...MUITypography,
    ...MUIFonts,
    ...MUITextInput,
    ...MuiList
  },
};

/**
 * Customizing the theme
 */

const theme = createTheme({
  ...palette,
  ...components,
});

export { theme };
