
import React, { lazy } from "react";

const ForgotPassword = lazy(() => import("../screens/unauthorized/forget_password"));
const Login = lazy(() => import("../screens/unauthorized/login"));
const MailVerification = lazy(() => import("../screens/unauthorized/mail_verification"));
const Register = lazy(() => import("../screens/unauthorized/register"));
const ResetPassword = lazy(() => import("../screens/unauthorized/reset_password"));
const Subscription = lazy(() => import("../screens/unauthorized/subscription"));
const Search = lazy(() => import("../screens/authorized/search/search"));
const PageNotFound = lazy(() => import("../screens/unauthorized/error/404"));
const SearchResult = lazy(() => import("../screens/authorized/search/search_result"));
const SearchedHistory = lazy(() => import("../screens/authorized/search/searched_history"));
const SettingPassword = lazy(() => import("../screens/authorized/settings/password"));
const Logout = lazy(() => import("../screens/authorized/logout"));

const ROUTES = [
    { path: "/login", component: <Login />, isAuthenticationRequired: false },
    { path: "/subscription/price", component: <Subscription/>, isAuthenticationRequired: false },
    { path: "/register", component: <Register />, isAuthenticationRequired: false },
    { path: "/forgot/password", component: <ForgotPassword />, isAuthenticationRequired: false },
    { path: "/reset/password", component: <ResetPassword />, isAuthenticationRequired: false },
    { path: "/mail/verified", component: <MailVerification />, isAuthenticationRequired: false },
    { path: "/", component: <Search />, isAuthenticationRequired: true },
    { path: "/search/result", component: <SearchResult />, isAuthenticationRequired: true },
    { path: "/search/history", component: <SearchedHistory />, isAuthenticationRequired: true },
    { path: "/settings/password", component: <SettingPassword />, isAuthenticationRequired: true },
    { path: "/logout", component: <Logout />, isAuthenticationRequired: true },
    { path: "*", component: <PageNotFound />, isAuthenticationRequired: false },
]
export {
    ROUTES
}