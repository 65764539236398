import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

const UnAuthorizeRoute = (props) => {
    const location = useLocation();
    const { isLoggedIn } = useSelector((state) => state.authentication);
    let key = location.pathname;
    switch (key) {
        case "/subscription/price":
            break;
        default:
            if (isLoggedIn) {
                return <Navigate to="/" state={{ from: location }} />;
            }
            break;
    }
   
    return props.children;
};

export default UnAuthorizeRoute;
